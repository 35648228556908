@import "settings"                                                                                                      // settings
@import "_mixins/mixins_list"                                                                                           // mixins
@import "_fonts/fonts_list"                                                                                             // fonts
@import "_fonts/fontello"                                                                                               // icons font
@import "_main/reset"                                                                                                   // reset styles
@import "_main/header"                                                                                                  // header

/* main page */
.main-page
  overflow: hidden
  @media (min-width: 960px)
    position: relative
    padding-bottom: 120px
  @media (min-width: 1200px)
    padding-bottom: 150px
  .main-title
    font-weight: 500
    @include adaptive-value('font-size', 48, 30, 1)
    @include adaptive-value('line-height', 50, 38, 1)
    margin-top: 40px
    position: relative
    z-index: 1
    @media (min-width: 414px)
      padding: 0 32px
    @media (min-width: 576px)
      margin-top: 25px
      padding: 0
    @media (min-width: 768px)
      max-width: 660px
    @media (min-width: 1200px)
      margin-top: 98px
    span
      color: $orange-color
      font-weight: 700
  &__content
    @media (min-width: 576px)
      display: flex
      align-items: stretch
      flex-direction: row-reverse
      margin-top: 40px
    @media (min-width: 1200px)
      margin-top: 52px
    &__left-side
      width: calc(100% + 30px)
      margin: 20px -15px 0 0
      position: relative
      padding: 0
      min-height: 105vw
      background-repeat: no-repeat
      background-position: center
      background-size: 100%
      @media (min-width: 576px)
        width: 100%
        flex: 1 1 100%
        margin: 0
        min-height: calc(100vw - 330px)
        background-position: 100% calc(50% + 20px)
        background-size: cover
      @media (min-width: 768px)
        min-height: 100%
        background-position: 50% calc(50% + 20px)
        background-size: contain
      @media (min-width: 960px)
        position: absolute
        height: 100%
        width: 60vw
        right: 4vw
        top: 5%
      @media (min-width: 1200px)
        top: 0
        right: 0
        width: 56vw
        height: 101%
    &__right-side
      position: relative
      z-index: 1
      max-width: 320px
      margin: -50px auto 0
      border-radius: 25px
      overflow: hidden
      @media (min-width: 576px)
        max-width: 300px
        flex: 0 0 300px
        margin: 0
      @media (min-width: 960px)
        margin-right: auto
      @media (min-width: 1200px)
        max-width: 480px
        flex: 0 0 480px
      .form-title
        background-color: $orange-color
        color: #fff
        text-align: center
        text-transform: uppercase
        font-weight: 500
        font-size: 18px
        padding: 20px 0
        @media (min-width: 1200px)
          font-size: 24px
          line-height: 24px
          padding: 22px 0
      form
        background-color: $blue-color
        padding: 20px 15px
        @media (min-width: 1200px)
          padding: 20px 28px 30px
        .form-subtitle
          color: #fff
          text-align: center
          font-size: 15px
          max-width: calc(100% + 20px)
          margin: 0 -10px 20px
          @media (min-width: 1200px)
            font-size: 16px
        .input-group
          padding: 0 5px
          width: 100%
          margin-bottom: 15px
          @media (min-width: 1200px)
            padding: 0
            max-width: 195px
          &.btn-group
            margin-top: 30px
            margin-bottom: 0
            @media (min-width: 1200px)
              max-width: 100%
              margin-top: 15px
              button
                margin: 0 auto
                max-width: 240px
                padding: 14px
        .input-row
            @media (min-width: 1200px)
              display: flex
              justify-content: space-between
            input
              width: 100%
              border-radius: 25px
              font-weight: 200
              font-size: 12px
              line-height: 14px
              padding: 11px 20px 14px
              border: 1px solid transparent
              transition: border-color .3s
              &.error-input
                border-color: red

.main-services
  margin-top: 80px
  @media (min-width: 960px)
    margin-top: 0
  &__title
    text-align: center
    @media (min-width: 576px)
      text-align: left
  &__content
    margin-top: 94px
    @media (min-width: 576px)
      display: flex
      flex-wrap: wrap
      align-items: stretch
      margin-top: 0
    &__item
      text-align: center
      border: 2px solid $blue-color
      border-radius: 25px
      max-width: 320px
      margin: 0 auto 110px auto
      @media (min-width: 576px)
        flex: 0 0 calc(50% - 10px)
        max-width: calc(50% - 10px)
        margin: 100px 20px 10px 0
        &:nth-of-type(2n)
          margin-right: 0
      @media (min-width: 960px)
        flex: 0 0 calc(33.3% - 10px)
        max-width: calc(33.3% - 10px)
        margin: 100px 15px 10px 0
        &:nth-of-type(2n)
          margin-right: 15px
        &:nth-of-type(3n)
          margin-right: 0
      @media (min-width: 1200px)
        max-width: 360px
        margin: 104px 40px 11px 0
        &:nth-of-type(2n)
          margin-right: 40px
        &:nth-of-type(3n)
          margin-right: 0
      .image-block
        width: 100px
        height: 100px
        display: flex
        align-items: center
        justify-content: center
        background-color: $orange-color
        border-radius: 50%
        margin: -54px auto 20px
        transition: background-color .3s
        @media (min-width: 1200px)
          width: 120px
          height: 120px
        a
          display: flex
          width: 100%
          height: 100%
          align-items: center
          justify-items: center
        &:hover
          background-color: #ff9300
        .service-image
          max-width: 70px
          max-height: 60px
          margin: 0 auto
          @media (min-width: 1200px)
            max-width: 80px
            max-height: 80px
      .descr-block
        .service-name
          display: inline-block
          min-width: 150px
          font-weight: 400
          @include adaptive-value('font-size', 21, 16, 1)
          @include adaptive-value('line-height', 25,20, 1)
          transition: color .3s
          &:hover
            color: $orange-color
        .service-link
          border: none
          border-radius: 25px
          background-color: $blue-color
          color: #fff
          text-transform: uppercase
          text-align: center
          display: inline-block
          font-weight: 700
          padding: 10px 40px
          transition: background-color .3s
          position: relative
          top: 20px
          @media (min-width: 414px)
            top: 30px
            margin: 10px auto
          @media (min-width: 576px)
            top: 20px
            margin: 0 auto
          @media (min-width: 1200px)
            top: 33px
            margin: 7px auto
            padding: 15px 50px
          &:hover
            background-color: $orange-color
      &:last-child
        margin-bottom: 0
        @media (min-width: 576px)
          margin-bottom: 10px
.main-answers
  padding-top: 100px
  padding-bottom: 50px
  overflow: hidden
  @media (min-width: 576px)
    padding-top: 70px
  @media (min-width: 1200px)
    padding-top: 160px
    padding-bottom: 120px
  &__title
    text-align: center
    @media (min-width: 576px)
      text-align: left
  &__content
    margin: 45px auto 0
    max-width: 320px
    position: relative
    @media (min-width: 576px)
      max-width: 550px
    @media (min-width: 768px)
      max-width: 100%
    @media (min-width: 960px)
      display: flex
      flex-wrap: wrap
      align-items: flex-start
    @media (min-width: 1200px)
      margin-top: 55px
    &__item
      margin-bottom: 20px
      @media (min-width: 576px)
        display: flex
        margin-bottom: 35px
      &:nth-of-type(2n)
        .image-block
          margin-left: 0
          margin-right: auto
          @media (min-width: 576px)
            margin-right: 0
            margin-left: 30px
        .descr-block
          &:before
            border-left: 24px solid $blue-color
            border-right: 24px solid transparent
            border-top: 24px solid transparent
            right: auto
            left: 67px
            @media (min-width: 576px)
              border-left: 0px solid transparent
              border-right: 24px solid transparent
              border-top: 24px solid $blue-color
              right: -24px
              left: auto
          &:after
            border-left: 20px solid #fff
            border-right: 20px solid transparent
            border-top: 20px solid transparent
            right: auto
            left: 69px
            @media (min-width: 576px)
              border-left: 20px solid transparent
              border-right: 20px solid transparent
              border-top: 20px solid #fff
              right: -19px
              left: auto
        @media (min-width: 576px)
          flex-direction: row-reverse
        @media (min-width: 960px)
          flex-direction: row
          margin-right: 0
          position: relative
          top: 80px
          .image-block
            margin-left: 0
            margin-right: 30px
          .descr-block
            &:before
              left: -24px
              top: 50px
              right: auto
              border-left: 24px solid transparent
              border-right: 0px solid transparent
              border-top: 24px solid $blue-color
            &:after
              border-left: 20px solid transparent
              border-right: 20px solid transparent
              border-top: 20px solid #fff
              right: auto
              left: -19px
              top: 52px
      @media (min-width: 960px)
        flex: 0 0 calc(50% - 10px)
        max-width: calc(50% - 10px)
        margin: 0 20px 30px 0
      @media (min-width: 1200px)
        flex: 0 0 calc(50% - 7px)
        max-width: calc(50% - 7px)
        margin: 0 14px 0px 0
    .image-block
      width: 100px
      height: 100px
      border-radius: 50%
      overflow: hidden
      border: 2px solid $orange-color
      margin-left: auto
      @media (min-width: 576px)
        flex: 0 0 110px
        width: 110px
        height: 110px
        margin-right: 30px
      @media (min-width: 1200px)
        margin-right: 35px
    .descr-block
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      border: 2px solid $blue-color
      border-radius: 25px
      background-color: #fff
      margin-top: 28px
      padding: 18px
      position: relative
      @media (min-width: 576px)
        flex: 0 0 calc(100% - 140px)
        max-width: calc(100% - 140px)
        margin-top: 0
      @media (min-width: 1200px)
        margin-top: 30px
        flex: 0 0 calc(100% - 145px)
        max-width: calc(100% - 145px)
      &:before
        content: ""
        display: block
        position: absolute
        width: 0
        height: 0
        border-left: 24px solid transparent
        border-right: 24px solid $blue-color
        border-top: 24px solid transparent
        right: 67px
        top: -24px
        @media (min-width: 576px)
          left: -24px
          top: 50px
          right: auto
          border-left: 24px solid transparent
          border-right: 0px solid transparent
          border-top: 24px solid $blue-color
        @media (min-width: 1200px)
          top: 39px
          left: -35px
          border-left: 35px solid transparent
          border-right: 0px solid transparent
          border-top: 35px solid $blue-color
      &:after
        content: ""
        display: block
        position: absolute
        width: 0
        height: 0
        border-left: 20px solid transparent
        border-right: 20px solid #fff
        border-top: 20px solid transparent
        right: 69px
        top: -19px
        @media (min-width: 576px)
          border-left: 20px solid transparent
          border-right: 20px solid transparent
          border-top: 20px solid #fff
          right: auto
          left: -19px
          top: 52px
        @media (min-width: 1200px)
          top: 41px
          left: -30px
          border-left: 31px solid transparent
          border-right: 31px solid transparent
          border-top: 31px solid #fff

      .name,.city
        width: auto
        color: $gray-color
        font-weight: 700
        @media (min-width: 768px)
          span
            display: inline-block !important
        @media (min-width: 960px)
          width: 100%
          display: block
        @media (min-width: 1200px)
          display: inline-block
          width: auto
      .answer-txt
        margin-top: 18px
        line-height: 19px
        color: #000
        @media (min-width: 960px)
          margin-top: 10px
        @media (min-width: 1200px)
          margin-top: 20px
    &__btn-row
      text-align: center
      @media (min-width: 960px)
        width: 100%
        flex: 0 0 100%
        margin-top: 60px
      @media (min-width: 1200px)
        margin-top: 105px
      .circles
        &.circles-top
          &:after
            content: ""
            display: block
            width: 48vw
            height: 48vw
            border-radius: 50%
            background-color: $blue-lighter
            position: absolute
            top: -3.5%
            right: -19vw
            z-index: -1
            @media (min-width: 576px)
              width: 38vw
              height: 38vw
              right: -10vw
            @media (min-width: 1200px)
              width: 302px
              height: 302px
              top: -27%
              right: 7%
          &:before
            content: ""
            display: block
            width: 73vw
            height: 73vw
            border-radius: 50%
            position: absolute
            left: -24.5vw
            top: 16.5%
            background-color: $orange-lighter
            z-index: -1
            @media (min-width: 576px)
              width: 60vw
              height: 60vw
            @media (min-width: 1200px)
              width: 399px
              height: 399px
              left: -25%
              top: -33%

        &.circles-center
          &:after
            content: ""
            display: block
            width: 63vw
            height: 63vw
            border-radius: 50%
            background-color: $blue-lighter
            position: absolute
            top: 56%
            right: -11vw
            z-index: -1
            @media (min-width: 576px)
              right: auto
              left: -15vw
              width: 55vw
              height: 55vw
              top: 65%
            @media (min-width: 1200px)
              width: 278px
              height: 278px
              left: -22%
              top: 60%
          &:before
            content: ""
            display: block
            width: 39vw
            height: 39vw
            border-radius: 50%
            position: absolute
            left: 10vw
            top: 42.5%
            background-color: $blue-lighter
            z-index: -1
            @media (min-width: 576px)
              display: none
            @media (min-width: 1200px)
              display: block
              background-color: $orange-lighter
              width: 338px
              height: 338px
              left: 23.5%
              top: 16%

        &.circles-bottom
          &:before
            content: ""
            display: block
            width: 57vw
            height: 57vw
            border-radius: 50%
            position: absolute
            left: -9.5vw
            top: 76.5%
            background-color: $orange-lighter
            z-index: -1
            @media (min-width: 576px)
              width: 35vw
              height: 35vw
              left: auto
              right: -20vw
              top: 85%
            @media (min-width: 1200px)
              width: 431px
              height: 431px
              right: -25%
              top: 33.5%
              background-color: $blue-lighter

      .answers-link
        display: inline-block
        margin-top: 20px
        padding: 10px 25px
        width: auto
        @media (min-width: 1200px)
          padding: 14px 25px

.questions
  background-color: $blue-color
  color: #fff
  padding: 58px 0 60px
  border-bottom: 2px solid #fff
  @media (min-width: 768px)
    padding: 58px 0 0
  @media (min-width: 1200px)
    padding: 70px 0
    border-bottom: none
    .container
      border: 6px solid #fff
      border-radius: 25px
      padding: 42px 60px 32px
  &__title
    text-align: center
    width: calc(100% + 30px)
    margin: 0 -15px
    padding-bottom: 28px
    border-bottom: 2px solid #fff
    @media (min-width: 768px)
      text-align: left
      border-bottom: none
      max-width: 100%
      margin: 0
      padding-bottom: 0
  &__subtitle
    line-height: 19px
    text-align: center
    @media (min-width: 414px)
      text-align: left
    @media (min-width: 1200px)
      font-size: 21px
      line-height: 25px

  &__content
    display: flex
    align-items: flex-end
    flex-wrap: wrap
    max-width: 345px
    margin: 0 auto
    @media (min-width: 576px)
      max-width: 345px
    @media (min-width: 768px)
      max-width: 100%
      display: flex
      flex-wrap: wrap
      align-items: flex-end
    &__left-side
      margin-top: 20px
      @media (min-width: 414px)
        flex: 0 0 65%
      @media (min-width: 768px)
        flex: 0 0 75%
      @media (min-width: 1200px)
        margin-top: 22px
      form
        max-width: 250px
        margin: 30px auto 0
        @media (min-width: 414px)
          max-width: 77%
          margin: 30px 0 58px
        @media (min-width: 768px)
          display: flex
          flex-wrap: wrap
          max-width: 100%
        @media (min-width: 1200px)
          margin: 40px auto 0
        .input-group
          margin-bottom: 20px
          @media (min-width: 768px)
            flex: 0 0 auto
            width: auto
            margin: 0 15px 0 0
          @media (min-width: 1200px)
            width: 225px
            margin: 0 20px 0 0
          &.btn-group
            position: absolute
            width: 100vw
            left: 0px
            border-top: 2px solid #fff
            text-align: center
            padding: 10px 0
            @media (min-width: 768px)
              position: relative
              left: 0
              width: auto
              padding: 0
              border: none
              margin: 0
            @media (min-width: 1200px)
              width: 240px
              margin: 0
            button
              display: inline-block
              width: 250px
              padding: 10px 65px
              @media (min-width: 414px)
                width: auto
              @media (min-width: 768px)
                width: auto
                padding: 10px 30px
              @media (min-width: 1200px)
                padding: 16px 0
                display: block
                width: 100%
          &.check-group
            label
              font-size: 12px
              line-height: 20px
              @media (min-width: 768px)
                justify-content: flex-start
                margin-left: 10px
            @media (min-width: 768px)
              order: 5
              flex: 0 0 100%
              width: 100%
              max-width: 100%
              margin-top: 10px
        input
          width: 100%
          border-radius: 25px
          font-weight: 200
          font-size: 12px
          line-height: 14px
          padding: 12px 20px 14px
          text-align: center
          border: 1px solid transparent
          transition: border-color .3s
          &.error-input
            border-color: red
          @media (min-width: 414px)
            text-align: left
          @media (min-width: 1200px)
            padding: 18px 16px
    &__right-side
      @media (min-width: 414px)
        flex: 0 0 35%
      @media (min-width: 768px)
        flex: 0 0 25%
        position: relative
      .questions-image
        width: 133%
        max-width: 133%
        position: relative
        right: 10px
        @media (min-width: 414px)
          display: block !important
          bottom: 38px
        @media (min-width: 768px)
          max-width: 100%
          width: 100%
          position: absolute
          margin: 0 auto
          left: 0
          right: 0
          bottom: 0
        @media (min-width: 1200px)
          bottom: -32px
          max-width: 240px
  

