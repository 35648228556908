@include font-face("Fontello", "fontello", 400, normal)

[class^="icon-"]:before,
[class*=" icon-"]:before
  font-family: Fontello
  font-style: normal
  font-weight: normal
  speak: never
  display: inline-block
  margin: 0 auto
  text-decoration: inherit
  text-align: center
  font-variant: normal
  text-transform: none

/* icons */
.icon-down-dir:before
  content: '\e800'
.icon-up-dir:before
  content: '\e801'
.icon-left-dir:before
  content: '\e802'
.icon-right-dir:before
  content: '\e803'
.icon-down-open-big:before
  content: '\e804'
.icon-left-open-big:before
  content: '\e805'
.icon-right-open-big:before
  content: '\e806'
.icon-up-open-big:before
  content: '\e807'
.icon-phone:before
  content: '\e808'
.icon-sc-telegram-svgrepo-com:before
  content: '\e81b'
.icon-whatsapp:before
  content: '\f232'