/* settings */
$burder-color: #fff

.hamburger
  padding: 0
  display: flex
  cursor: pointer
  transition-property: opacity, filter
  transition-duration: 0.15s
  transition-timing-function: linear
  font: inherit
  color: inherit
  text-transform: none
  background-color: transparent
  border: 0
  overflow: visible
  position: relative
  order: 5
  z-index: 20
  margin: 0 0 0 auto
  @media (min-width: 768px)
    margin: 0
  @media (min-width: 1200px)
    display: none
  &:hover
    opacity: 0.7
  &.is-active
    &:hover
      opacity: 0.7
    .hamburger-inner
      background-color: $burder-color
      &::before, &::after
        background-color: $burder-color
.hamburger-box
  width: 42px
  height: 24px
  display: inline-block
  position: relative
.hamburger-inner
  display: block
  top: 50%
  margin-top: -2px
  width: 42px
  height: 2px
  background-color: $burder-color
  border-radius: 4px
  position: absolute
  transition-property: transform
  transition-duration: 0.15s
  transition-timing-function: ease
  &::before, &::after
    width: 42px
    height: 2px
    background-color: $burder-color
    border-radius: 4px
    position: absolute
    transition-property: transform
    transition-duration: 0.15s
    transition-timing-function: ease
  &::before, &::after
    content: ""
    display: block
  &::before
    top: -10px
  &::after
    bottom: -10px

/* slide animate */
.hamburger--slider
  .hamburger-inner
    top: 3px
    &::before
      top: 10px
      transition-property: transform, opacity
      transition-timing-function: ease
      transition-duration: 0.15s
    &::after
      top: 20px
  &.is-active .hamburger-inner
    transform: translate3d(0, 10px, 0) rotate(45deg)
    &::before
      transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0)
      opacity: 0
    &::after
      transform: translate3d(0, -20px, 0) rotate(-90deg)