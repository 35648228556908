@import "../_mixins/mixins_list"
.header
  background-color: $blue-color
  .top-phone
    background-color: #fff
    display: block
    width: 50px
    height: 50px
    position: fixed
    top: 40px
    right: 40px
    z-index: 99
    border-radius: 50%
    transform: scale(1)
    box-shadow: 0 0 0 0 rgba($orange-color, 1)
    animation: pulse-main 2s infinite
    @media (min-width: 1200px)
      top: 80px
      right: 100px
    &:before
      content: ''
      display: block
      width: 50px
      height: 50px
      border: 1px solid $orange-color
      border-radius: 50%
      position: absolute
      top: 0
      left: 0
      animation: pulse-border 2s infinite
  &__content
    display: flex
    align-items: center
    padding: 20px 0
    position: relative
    @media (min-width: 414px)
      padding: 20px 30px
    @media (min-width: 768px)
      padding: 20px 0
    @media (min-width: 1200px)
      padding: 8px 0 11px
    .logo-link
      display: inline-flex
      flex: 0 0 auto
      align-items: flex-end
      @media (min-width: 1200px)
        margin-right: 100px
    .logo-image
      display: block
      max-width: 60px
      flex: 0 0 60px
      @media (min-width: 1200px)
        max-width: 80px
        flex: 0 0 80px
        position: relative
        left: -3px
    .logo-descr
      display: flex
      flex-direction: column
      color: #fff
      margin-left: 10px
      flex: 0 0 auto
      @media (min-width: 1200px)
        margin-left: 12px
      .logo-title
        font-family: Glametrix, sans-serif
        font-weight: 700
        font-size: 26px
        line-height: 26px
        display: inline-block
        width: 100%
        @media (min-width: 1200px)
          font-size: 34px
          line-height: 34px
      .logo-subtitle
        font-size: 12px
        line-height: 14px
        display: inline-block
        margin-top: -3px
        max-width: 100px
        @media (min-width: 768px)
          margin-bottom: 5px
        @media (min-width: 1200px)
          max-width: 140px
          font-size: 16px
          line-height: 20px
          margin: 0

    @import "../_plugins/_hamburger"
    .main-menu
      position: absolute
      top: 100%
      left: 0
      height: calc(100vh - 100px)
      overflow-y: scroll
      width: calc(100% + 30px)
      margin: 0 -15px
      background-color: $blue-color
      border-top: 2px solid #fff
      z-index: 10
      &__list
        margin: 0
        padding: 30px 15px
        @media (min-width: 1200px)
          display: flex
          align-items: center
          padding: 0 15px
      &__item
        text-align: center
        margin-top: 15px
        @media (min-width: 1200px)
          margin: 0 24px
          padding: 30px 0
        &:nth-of-type(1)
          margin-top: 0
        &.submenu-item
          .submenu-link
            color: #fff
            display: inline-block
            margin-left: 5px
          .submenu__list
            margin: 10px 0
            padding: 10px 0
            border-top: 2px solid rgba(#fff, .5)
            border-bottom: 2px solid rgba(#fff, .5)
            @media (min-width: 1200px)
              display: none
              position: absolute
              border-radius: 25px
              background-color: #fff
              border: 2px solid $blue-color
              text-align: left
              padding: 20px 0 20px 15px
              margin: 15px 0 0
              width: 250px
            .submenu__item
              margin-top: 10px
              @media (min-width: 1200px)
                position: relative
                padding-left: 15px
                &:before
                  content: ''
                  display: block
                  width: 6px
                  height: 6px
                  background-color: $blue-color
                  border-radius: 50%
                  position: absolute
                  left: 0
                  top: 7px
              &:nth-of-type(1)
                margin-top: 0
              &.submenu-item
                display: flex
                justify-content: center
                align-items: center
                flex-wrap: wrap
                @media (min-width: 1200px)
                  justify-content: flex-start
                  display: block
                  &:hover
                    .submenu-list
                      display: block!important
                      position: absolute
                      top: -30px
                      left: calc(100% - 5px)
                      background-color: #fff
                      border: 2px solid #396eb0
                      border-radius: 25px
                      padding: 20px 15px
                      width: auto
                      min-width: 245px
                      &__item
                        position: relative
                        padding-left: 15px
                        &:before
                          content: ''
                          display: block
                          width: 6px
                          height: 6px
                          background-color: $blue-color
                          border-radius: 50%
                          position: absolute
                          left: 0
                          top: 7px
                      &__link
                        color: $txt-color
                .submenu__link
                  display: inline-flex
                  flex: 0 0 auto
                  width: auto
                  margin-right: 10px
                .submenu-sub-link
                  color: #fff
                  transform: rotate(-90deg)
                  position: relative
                  top: 1px
                  transition: transform .3s
                  &.active
                    transform: rotate(0)
                  @media (min-width: 1200px)
                    display: none
                .submenu-list
                  border-bottom: 1px solid #fff
                  display: block
                  width: 100%
                  padding: 15px 0
                  margin-top: 10px
                  background-color: rgba(255,255,255,.05)
                  &__item
                    display: block
                    margin-top: 10px
                    &:nth-of-type(1)
                      margin-top: 0
                  &__link
                    color: #fff


            .submenu__link
              color: #fff
              @media (min-width: 1200px)
                color: $txt-color
          @media (min-width: 1200px)
            &:hover
              .submenu__list
                display: block

      &__link
        display: inline-block
        color: #fff
        text-transform: uppercase
        font-weight: 500
        @media (min-width: 1200px)
          text-transform: none
          font-size: 18px
          line-height: 21px
      .phone-block
        margin-top: 25px
        text-align: center
        @media (min-width: 1200px)
          display: none !important
        .phone-link
          color: #fff
          font-weight: 500
          font-size: 18px
      .social-links
        display: flex
        align-items: center
        justify-content: center
        margin-top: 15px
        @media (min-width: 1200px)
          display: none !important
        &__item
          display: inline-flex
          align-items: center
          justify-content: center
          color: #fff
          width: 40px
          height: 40px
          background-color: $orange-color
          border-radius: 50%
          margin: 0 8px
          .icon
            display: flex
            align-items: center
            justify-content: center
            font-size: 38px
            line-height: 38px
            &.icon-whatsapp
              font-size: 28px
              line-height: 28px
      @media (min-width: 1200px)
        display: block !important
        position: relative
        top: 0
        height: auto
        overflow: visible
        width: auto
        background-color: transparent
        border: none
        margin: 0 auto
    & > .social-links
      @media (min-width: 768px)
        display: inline-flex !important
        align-items: center
        justify-content: center
        margin: 0 0 0 auto
        .social-links__item
          margin: 0 5px
      @media (min-width: 1200px)
        margin: 0
        .social-links__item
          margin: 0 10px
    .phone-block
      @media (min-width: 768px)
        display: block !important
        margin: 0 30px 0 20px
        .phone-link
          color: #fff
          font-weight: 600
          font-size: 20px
          line-height: 20px
      @media (min-width: 1200px)
        margin: 0 0 0 30px
        .phone-descr
          display: block !important
          color: #fff
          font-weight: 500
          font-size: 16px
          line-height: 19px
        .phone-link
          font-size: 24px
          line-height: 28px
          display: inline-block
          margin-bottom: 4px

@keyframes pulse-main
  0%
    transform: scale(0.95)
    box-shadow: 0 0 0 0 rgba($orange-color, 0.7)
  70%
    transform: scale(1)
    box-shadow: 0 0 0 20px rgba($orange-color, 0)
  100%
    transform: scale(0.95)
    box-shadow: 0 0 0 0 rgba($orange-color, 0)
@keyframes pulse-border
  0%
    opacity: 0
    transform: scale(0.95)
  50%
    opacity: 1
  100%
    opacity: 0
    transform: scale(3)



