@font-face {
  font-family: "Glametrix";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/GlametrixBold/GlametrixBold.eot");
  src: url("../fonts/GlametrixBold/GlametrixBold.eot?#iefix") format("embedded-opentype"), url("../fonts/GlametrixBold/GlametrixBold.woff") format("woff"), url("../fonts/GlametrixBold/GlametrixBold.woff2") format("woff2"), url("../fonts/GlametrixBold/GlametrixBold.ttf") format("truetype");
}
@font-face {
  font-family: "Glametrix";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/GlametrixLight/GlametrixLight.eot");
  src: url("../fonts/GlametrixLight/GlametrixLight.eot?#iefix") format("embedded-opentype"), url("../fonts/GlametrixLight/GlametrixLight.woff") format("woff"), url("../fonts/GlametrixLight/GlametrixLight.woff2") format("woff2"), url("../fonts/GlametrixLight/GlametrixLight.ttf") format("truetype");
}
@font-face {
  font-family: "Glametrix";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/GlametrixRegular/GlametrixRegular.eot");
  src: url("../fonts/GlametrixRegular/GlametrixRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/GlametrixRegular/GlametrixRegular.woff") format("woff"), url("../fonts/GlametrixRegular/GlametrixRegular.woff2") format("woff2"), url("../fonts/GlametrixRegular/GlametrixRegular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/RobotoBold/RobotoBold.eot");
  src: url("../fonts/RobotoBold/RobotoBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/RobotoBold.woff") format("woff"), url("../fonts/RobotoBold/RobotoBold.woff2") format("woff2"), url("../fonts/RobotoBold/RobotoBold.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/RobotoLight/RobotoLight.eot");
  src: url("../fonts/RobotoLight/RobotoLight.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/RobotoLight.woff") format("woff"), url("../fonts/RobotoLight/RobotoLight.woff2") format("woff2"), url("../fonts/RobotoLight/RobotoLight.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/RobotoMedium/RobotoMedium.eot");
  src: url("../fonts/RobotoMedium/RobotoMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMedium/RobotoMedium.woff") format("woff"), url("../fonts/RobotoMedium/RobotoMedium.woff2") format("woff2"), url("../fonts/RobotoMedium/RobotoMedium.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/RobotoRegular/RobotoRegular.eot");
  src: url("../fonts/RobotoRegular/RobotoRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/RobotoRegular.woff") format("woff"), url("../fonts/RobotoRegular/RobotoRegular.woff2") format("woff2"), url("../fonts/RobotoRegular/RobotoRegular.ttf") format("truetype");
}
@font-face {
  font-family: "Fontello";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/fontello/fontello.eot");
  src: url("../fonts/fontello/fontello.eot?#iefix") format("embedded-opentype"), url("../fonts/fontello/fontello.woff") format("woff"), url("../fonts/fontello/fontello.woff2") format("woff2"), url("../fonts/fontello/fontello.ttf") format("truetype");
}
[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: Fontello;
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  margin: 0 auto;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
}

/* icons */
.icon-down-dir:before {
  content: "\e800";
}

.icon-up-dir:before {
  content: "\e801";
}

.icon-left-dir:before {
  content: "\e802";
}

.icon-right-dir:before {
  content: "\e803";
}

.icon-down-open-big:before {
  content: "\e804";
}

.icon-left-open-big:before {
  content: "\e805";
}

.icon-right-open-big:before {
  content: "\e806";
}

.icon-up-open-big:before {
  content: "\e807";
}

.icon-phone:before {
  content: "\e808";
}

.icon-sc-telegram-svgrepo-com:before {
  content: "\e81b";
}

.icon-whatsapp:before {
  content: "\f232";
}

* {
  box-sizing: border-box;
  outline: none;
}
*::before, *::after {
  box-sizing: border-box;
}

body,
h1, h2, h3, h4, h5, h6, p,
ul[class], ol[class], li,
figure, figcaption, blockquote,
dl, dd {
  margin: 0;
}

ul[class], ol[class] {
  padding: 0;
  list-style-type: none;
}

button, label {
  cursor: pointer;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}
a[class] {
  text-decoration: none;
  color: inherit;
}

input, button, textarea, select {
  font: inherit;
}

img {
  max-width: 100%;
}

/* main */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 320px;
  position: relative;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #171717;
  background-color: #fff;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 0;
}
body::-webkit-scrollbar-thumb {
  background: #396EB0;
  border-radius: 0;
  transition: background-color 0.3s;
}
body::-webkit-scrollbar-thumb:hover {
  background: #EA9623;
}
body::-webkit-scrollbar-track {
  background: #373737;
  border-radius: 0;
}
body {
  scrollbar-face-color: #396EB0;
  scrollbar-track-color: #373737;
}
body.overflow {
  overflow: hidden;
}

.container {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1170px;
}
@media (min-width: 1200px) {
  .container {
    padding: 0 5px;
  }
}

a:hover {
  text-decoration: none !important;
}

.header {
  background-color: #396EB0;
}
.header .top-phone {
  background-color: #fff;
  display: block;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 99;
  border-radius: 50%;
  transform: scale(1);
  box-shadow: 0 0 0 0 #ea9623;
  animation: pulse-main 2s infinite;
}
@media (min-width: 1200px) {
  .header .top-phone {
    top: 80px;
    right: 100px;
  }
}
.header .top-phone:before {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  border: 1px solid #EA9623;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  animation: pulse-border 2s infinite;
}
.header__content {
  display: flex;
  align-items: center;
  padding: 20px 0;
  position: relative;
  /* settings */
  /* slide animate */
}
@media (min-width: 414px) {
  .header__content {
    padding: 20px 30px;
  }
}
@media (min-width: 768px) {
  .header__content {
    padding: 20px 0;
  }
}
@media (min-width: 1200px) {
  .header__content {
    padding: 8px 0 11px;
  }
}
.header__content .logo-link {
  display: inline-flex;
  flex: 0 0 auto;
  align-items: flex-end;
}
@media (min-width: 1200px) {
  .header__content .logo-link {
    margin-right: 100px;
  }
}
.header__content .logo-image {
  display: block;
  max-width: 60px;
  flex: 0 0 60px;
}
@media (min-width: 1200px) {
  .header__content .logo-image {
    max-width: 80px;
    flex: 0 0 80px;
    position: relative;
    left: -3px;
  }
}
.header__content .logo-descr {
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-left: 10px;
  flex: 0 0 auto;
}
@media (min-width: 1200px) {
  .header__content .logo-descr {
    margin-left: 12px;
  }
}
.header__content .logo-descr .logo-title {
  font-family: Glametrix, sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  display: inline-block;
  width: 100%;
}
@media (min-width: 1200px) {
  .header__content .logo-descr .logo-title {
    font-size: 34px;
    line-height: 34px;
  }
}
.header__content .logo-descr .logo-subtitle {
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
  margin-top: -3px;
  max-width: 100px;
}
@media (min-width: 768px) {
  .header__content .logo-descr .logo-subtitle {
    margin-bottom: 5px;
  }
}
@media (min-width: 1200px) {
  .header__content .logo-descr .logo-subtitle {
    max-width: 140px;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }
}
.header__content .hamburger {
  padding: 0;
  display: flex;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible;
  position: relative;
  order: 5;
  z-index: 20;
  margin: 0 0 0 auto;
}
@media (min-width: 768px) {
  .header__content .hamburger {
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .header__content .hamburger {
    display: none;
  }
}
.header__content .hamburger:hover {
  opacity: 0.7;
}
.header__content .hamburger.is-active:hover {
  opacity: 0.7;
}
.header__content .hamburger.is-active .hamburger-inner {
  background-color: #fff;
}
.header__content .hamburger.is-active .hamburger-inner::before, .header__content .hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}
.header__content .hamburger-box {
  width: 42px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.header__content .hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 42px;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.header__content .hamburger-inner::before, .header__content .hamburger-inner::after {
  width: 42px;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.header__content .hamburger-inner::before, .header__content .hamburger-inner::after {
  content: "";
  display: block;
}
.header__content .hamburger-inner::before {
  top: -10px;
}
.header__content .hamburger-inner::after {
  bottom: -10px;
}
.header__content .hamburger--slider .hamburger-inner {
  top: 3px;
}
.header__content .hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.header__content .hamburger--slider .hamburger-inner::after {
  top: 20px;
}
.header__content .hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.header__content .hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
.header__content .hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}
.header__content .main-menu {
  position: absolute;
  top: 100%;
  left: 0;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  width: calc(100% + 30px);
  margin: 0 -15px;
  background-color: #396EB0;
  border-top: 2px solid #fff;
  z-index: 10;
}
.header__content .main-menu__list {
  margin: 0;
  padding: 30px 15px;
}
@media (min-width: 1200px) {
  .header__content .main-menu__list {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}
.header__content .main-menu__item {
  text-align: center;
  margin-top: 15px;
}
@media (min-width: 1200px) {
  .header__content .main-menu__item {
    margin: 0 24px;
    padding: 30px 0;
  }
}
.header__content .main-menu__item:nth-of-type(1) {
  margin-top: 0;
}
.header__content .main-menu__item.submenu-item .submenu-link {
  color: #fff;
  display: inline-block;
  margin-left: 5px;
}
.header__content .main-menu__item.submenu-item .submenu__list {
  margin: 10px 0;
  padding: 10px 0;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}
@media (min-width: 1200px) {
  .header__content .main-menu__item.submenu-item .submenu__list {
    display: none;
    position: absolute;
    border-radius: 25px;
    background-color: #fff;
    border: 2px solid #396EB0;
    text-align: left;
    padding: 20px 0 20px 15px;
    margin: 15px 0 0;
    width: 250px;
  }
}
.header__content .main-menu__item.submenu-item .submenu__list .submenu__item {
  margin-top: 10px;
}
@media (min-width: 1200px) {
  .header__content .main-menu__item.submenu-item .submenu__list .submenu__item {
    position: relative;
    padding-left: 15px;
  }
  .header__content .main-menu__item.submenu-item .submenu__list .submenu__item:before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: #396EB0;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 7px;
  }
}
.header__content .main-menu__item.submenu-item .submenu__list .submenu__item:nth-of-type(1) {
  margin-top: 0;
}
.header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 1200px) {
  .header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item {
    justify-content: flex-start;
    display: block;
  }
  .header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item:hover .submenu-list {
    display: block !important;
    position: absolute;
    top: -30px;
    left: calc(100% - 5px);
    background-color: #fff;
    border: 2px solid #396eb0;
    border-radius: 25px;
    padding: 20px 15px;
    width: auto;
    min-width: 245px;
  }
  .header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item:hover .submenu-list__item {
    position: relative;
    padding-left: 15px;
  }
  .header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item:hover .submenu-list__item:before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: #396EB0;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 7px;
  }
  .header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item:hover .submenu-list__link {
    color: #171717;
  }
}
.header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item .submenu__link {
  display: inline-flex;
  flex: 0 0 auto;
  width: auto;
  margin-right: 10px;
}
.header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item .submenu-sub-link {
  color: #fff;
  transform: rotate(-90deg);
  position: relative;
  top: 1px;
  transition: transform 0.3s;
}
.header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item .submenu-sub-link.active {
  transform: rotate(0);
}
@media (min-width: 1200px) {
  .header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item .submenu-sub-link {
    display: none;
  }
}
.header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item .submenu-list {
  border-bottom: 1px solid #fff;
  display: block;
  width: 100%;
  padding: 15px 0;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.05);
}
.header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item .submenu-list__item {
  display: block;
  margin-top: 10px;
}
.header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item .submenu-list__item:nth-of-type(1) {
  margin-top: 0;
}
.header__content .main-menu__item.submenu-item .submenu__list .submenu__item.submenu-item .submenu-list__link {
  color: #fff;
}
.header__content .main-menu__item.submenu-item .submenu__list .submenu__link {
  color: #fff;
}
@media (min-width: 1200px) {
  .header__content .main-menu__item.submenu-item .submenu__list .submenu__link {
    color: #171717;
  }
}
@media (min-width: 1200px) {
  .header__content .main-menu__item.submenu-item:hover .submenu__list {
    display: block;
  }
}
.header__content .main-menu__link {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}
@media (min-width: 1200px) {
  .header__content .main-menu__link {
    text-transform: none;
    font-size: 18px;
    line-height: 21px;
  }
}
.header__content .main-menu .phone-block {
  margin-top: 25px;
  text-align: center;
}
@media (min-width: 1200px) {
  .header__content .main-menu .phone-block {
    display: none !important;
  }
}
.header__content .main-menu .phone-block .phone-link {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}
.header__content .main-menu .social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
@media (min-width: 1200px) {
  .header__content .main-menu .social-links {
    display: none !important;
  }
}
.header__content .main-menu .social-links__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: #EA9623;
  border-radius: 50%;
  margin: 0 8px;
}
.header__content .main-menu .social-links__item .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  line-height: 38px;
}
.header__content .main-menu .social-links__item .icon.icon-whatsapp {
  font-size: 28px;
  line-height: 28px;
}
@media (min-width: 1200px) {
  .header__content .main-menu {
    display: block !important;
    position: relative;
    top: 0;
    height: auto;
    overflow: visible;
    width: auto;
    background-color: transparent;
    border: none;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .header__content > .social-links {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 auto;
  }
  .header__content > .social-links .social-links__item {
    margin: 0 5px;
  }
}
@media (min-width: 1200px) {
  .header__content > .social-links {
    margin: 0;
  }
  .header__content > .social-links .social-links__item {
    margin: 0 10px;
  }
}
@media (min-width: 768px) {
  .header__content .phone-block {
    display: block !important;
    margin: 0 30px 0 20px;
  }
  .header__content .phone-block .phone-link {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  .header__content .phone-block {
    margin: 0 0 0 30px;
  }
  .header__content .phone-block .phone-descr {
    display: block !important;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .header__content .phone-block .phone-link {
    font-size: 24px;
    line-height: 28px;
    display: inline-block;
    margin-bottom: 4px;
  }
}

@keyframes pulse-main {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(234, 150, 35, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(234, 150, 35, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(234, 150, 35, 0);
  }
}
@keyframes pulse-border {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}
/* main page */
.main-page {
  overflow: hidden;
}
@media (min-width: 960px) {
  .main-page {
    position: relative;
    padding-bottom: 120px;
  }
}
@media (min-width: 1200px) {
  .main-page {
    padding-bottom: 150px;
  }
}
.main-page .main-title {
  font-weight: 500;
  font-size: 48px;
  line-height: 50px;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1170px) {
  .main-page .main-title {
    font-size: calc(30px + 18 *((100vw - 320px) / 850));
  }
}
@media (max-width: 1170px) {
  .main-page .main-title {
    line-height: calc(38px + 12 *((100vw - 320px) / 850));
  }
}
@media (min-width: 414px) {
  .main-page .main-title {
    padding: 0 32px;
  }
}
@media (min-width: 576px) {
  .main-page .main-title {
    margin-top: 25px;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .main-page .main-title {
    max-width: 660px;
  }
}
@media (min-width: 1200px) {
  .main-page .main-title {
    margin-top: 98px;
  }
}
.main-page .main-title span {
  color: #EA9623;
  font-weight: 700;
}
@media (min-width: 576px) {
  .main-page__content {
    display: flex;
    align-items: stretch;
    flex-direction: row-reverse;
    margin-top: 40px;
  }
}
@media (min-width: 1200px) {
  .main-page__content {
    margin-top: 52px;
  }
}
.main-page__content__left-side {
  width: calc(100% + 30px);
  margin: 20px -15px 0 0;
  position: relative;
  padding: 0;
  min-height: 105vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
@media (min-width: 576px) {
  .main-page__content__left-side {
    width: 100%;
    flex: 1 1 100%;
    margin: 0;
    min-height: calc(100vw - 330px);
    background-position: 100% calc(50% + 20px);
    background-size: cover;
  }
}
@media (min-width: 768px) {
  .main-page__content__left-side {
    min-height: 100%;
    background-position: 50% calc(50% + 20px);
    background-size: contain;
  }
}
@media (min-width: 960px) {
  .main-page__content__left-side {
    position: absolute;
    height: 100%;
    width: 60vw;
    right: 4vw;
    top: 5%;
  }
}
@media (min-width: 1200px) {
  .main-page__content__left-side {
    top: 0;
    right: 0;
    width: 56vw;
    height: 101%;
  }
}
.main-page__content__right-side {
  position: relative;
  z-index: 1;
  max-width: 320px;
  margin: -50px auto 0;
  border-radius: 25px;
  overflow: hidden;
}
@media (min-width: 576px) {
  .main-page__content__right-side {
    max-width: 300px;
    flex: 0 0 300px;
    margin: 0;
  }
}
@media (min-width: 960px) {
  .main-page__content__right-side {
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .main-page__content__right-side {
    max-width: 480px;
    flex: 0 0 480px;
  }
}
.main-page__content__right-side .form-title {
  background-color: #EA9623;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  padding: 20px 0;
}
@media (min-width: 1200px) {
  .main-page__content__right-side .form-title {
    font-size: 24px;
    line-height: 24px;
    padding: 22px 0;
  }
}
.main-page__content__right-side form {
  background-color: #396EB0;
  padding: 20px 15px;
}
@media (min-width: 1200px) {
  .main-page__content__right-side form {
    padding: 20px 28px 30px;
  }
}
.main-page__content__right-side form .form-subtitle {
  color: #fff;
  text-align: center;
  font-size: 15px;
  max-width: calc(100% + 20px);
  margin: 0 -10px 20px;
}
@media (min-width: 1200px) {
  .main-page__content__right-side form .form-subtitle {
    font-size: 16px;
  }
}
.main-page__content__right-side form .input-group {
  padding: 0 5px;
  width: 100%;
  margin-bottom: 15px;
}
@media (min-width: 1200px) {
  .main-page__content__right-side form .input-group {
    padding: 0;
    max-width: 195px;
  }
}
.main-page__content__right-side form .input-group.btn-group {
  margin-top: 30px;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .main-page__content__right-side form .input-group.btn-group {
    max-width: 100%;
    margin-top: 15px;
  }
  .main-page__content__right-side form .input-group.btn-group button {
    margin: 0 auto;
    max-width: 240px;
    padding: 14px;
  }
}
@media (min-width: 1200px) {
  .main-page__content__right-side form .input-row {
    display: flex;
    justify-content: space-between;
  }
}
.main-page__content__right-side form .input-row input {
  width: 100%;
  border-radius: 25px;
  font-weight: 200;
  font-size: 12px;
  line-height: 14px;
  padding: 11px 20px 14px;
  border: 1px solid transparent;
  transition: border-color 0.3s;
}
.main-page__content__right-side form .input-row input.error-input {
  border-color: red;
}

.main-services {
  margin-top: 80px;
}
@media (min-width: 960px) {
  .main-services {
    margin-top: 0;
  }
}
.main-services__title {
  text-align: center;
}
@media (min-width: 576px) {
  .main-services__title {
    text-align: left;
  }
}
.main-services__content {
  margin-top: 94px;
}
@media (min-width: 576px) {
  .main-services__content {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: 0;
  }
}
.main-services__content__item {
  text-align: center;
  border: 2px solid #396EB0;
  border-radius: 25px;
  max-width: 320px;
  margin: 0 auto 110px auto;
}
@media (min-width: 576px) {
  .main-services__content__item {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin: 100px 20px 10px 0;
  }
  .main-services__content__item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media (min-width: 960px) {
  .main-services__content__item {
    flex: 0 0 calc(33.3% - 10px);
    max-width: calc(33.3% - 10px);
    margin: 100px 15px 10px 0;
  }
  .main-services__content__item:nth-of-type(2n) {
    margin-right: 15px;
  }
  .main-services__content__item:nth-of-type(3n) {
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .main-services__content__item {
    max-width: 360px;
    margin: 104px 40px 11px 0;
  }
  .main-services__content__item:nth-of-type(2n) {
    margin-right: 40px;
  }
  .main-services__content__item:nth-of-type(3n) {
    margin-right: 0;
  }
}
.main-services__content__item .image-block {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EA9623;
  border-radius: 50%;
  margin: -54px auto 20px;
  transition: background-color 0.3s;
}
@media (min-width: 1200px) {
  .main-services__content__item .image-block {
    width: 120px;
    height: 120px;
  }
}
.main-services__content__item .image-block a {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;
}
.main-services__content__item .image-block:hover {
  background-color: #ff9300;
}
.main-services__content__item .image-block .service-image {
  max-width: 70px;
  max-height: 60px;
  margin: 0 auto;
}
@media (min-width: 1200px) {
  .main-services__content__item .image-block .service-image {
    max-width: 80px;
    max-height: 80px;
  }
}
.main-services__content__item .descr-block .service-name {
  display: inline-block;
  min-width: 150px;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  transition: color 0.3s;
}
@media (max-width: 1170px) {
  .main-services__content__item .descr-block .service-name {
    font-size: calc(16px + 5 *((100vw - 320px) / 850));
  }
}
@media (max-width: 1170px) {
  .main-services__content__item .descr-block .service-name {
    line-height: calc(20px + 5 *((100vw - 320px) / 850));
  }
}
.main-services__content__item .descr-block .service-name:hover {
  color: #EA9623;
}
.main-services__content__item .descr-block .service-link {
  border: none;
  border-radius: 25px;
  background-color: #396EB0;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  font-weight: 700;
  padding: 10px 40px;
  transition: background-color 0.3s;
  position: relative;
  top: 20px;
}
@media (min-width: 414px) {
  .main-services__content__item .descr-block .service-link {
    top: 30px;
    margin: 10px auto;
  }
}
@media (min-width: 576px) {
  .main-services__content__item .descr-block .service-link {
    top: 20px;
    margin: 0 auto;
  }
}
@media (min-width: 1200px) {
  .main-services__content__item .descr-block .service-link {
    top: 33px;
    margin: 7px auto;
    padding: 15px 50px;
  }
}
.main-services__content__item .descr-block .service-link:hover {
  background-color: #EA9623;
}
.main-services__content__item:last-child {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .main-services__content__item:last-child {
    margin-bottom: 10px;
  }
}

.main-answers {
  padding-top: 100px;
  padding-bottom: 50px;
  overflow: hidden;
}
@media (min-width: 576px) {
  .main-answers {
    padding-top: 70px;
  }
}
@media (min-width: 1200px) {
  .main-answers {
    padding-top: 160px;
    padding-bottom: 120px;
  }
}
.main-answers__title {
  text-align: center;
}
@media (min-width: 576px) {
  .main-answers__title {
    text-align: left;
  }
}
.main-answers__content {
  margin: 45px auto 0;
  max-width: 320px;
  position: relative;
}
@media (min-width: 576px) {
  .main-answers__content {
    max-width: 550px;
  }
}
@media (min-width: 768px) {
  .main-answers__content {
    max-width: 100%;
  }
}
@media (min-width: 960px) {
  .main-answers__content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
@media (min-width: 1200px) {
  .main-answers__content {
    margin-top: 55px;
  }
}
.main-answers__content__item {
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .main-answers__content__item {
    display: flex;
    margin-bottom: 35px;
  }
}
.main-answers__content__item:nth-of-type(2n) .image-block {
  margin-left: 0;
  margin-right: auto;
}
@media (min-width: 576px) {
  .main-answers__content__item:nth-of-type(2n) .image-block {
    margin-right: 0;
    margin-left: 30px;
  }
}
.main-answers__content__item:nth-of-type(2n) .descr-block:before {
  border-left: 24px solid #396EB0;
  border-right: 24px solid transparent;
  border-top: 24px solid transparent;
  right: auto;
  left: 67px;
}
@media (min-width: 576px) {
  .main-answers__content__item:nth-of-type(2n) .descr-block:before {
    border-left: 0px solid transparent;
    border-right: 24px solid transparent;
    border-top: 24px solid #396EB0;
    right: -24px;
    left: auto;
  }
}
.main-answers__content__item:nth-of-type(2n) .descr-block:after {
  border-left: 20px solid #fff;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  right: auto;
  left: 69px;
}
@media (min-width: 576px) {
  .main-answers__content__item:nth-of-type(2n) .descr-block:after {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
    right: -19px;
    left: auto;
  }
}
@media (min-width: 576px) {
  .main-answers__content__item:nth-of-type(2n) {
    flex-direction: row-reverse;
  }
}
@media (min-width: 960px) {
  .main-answers__content__item:nth-of-type(2n) {
    flex-direction: row;
    margin-right: 0;
    position: relative;
    top: 80px;
  }
  .main-answers__content__item:nth-of-type(2n) .image-block {
    margin-left: 0;
    margin-right: 30px;
  }
  .main-answers__content__item:nth-of-type(2n) .descr-block:before {
    left: -24px;
    top: 50px;
    right: auto;
    border-left: 24px solid transparent;
    border-right: 0px solid transparent;
    border-top: 24px solid #396EB0;
  }
  .main-answers__content__item:nth-of-type(2n) .descr-block:after {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
    right: auto;
    left: -19px;
    top: 52px;
  }
}
@media (min-width: 960px) {
  .main-answers__content__item {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin: 0 20px 30px 0;
  }
}
@media (min-width: 1200px) {
  .main-answers__content__item {
    flex: 0 0 calc(50% - 7px);
    max-width: calc(50% - 7px);
    margin: 0 14px 0px 0;
  }
}
.main-answers__content .image-block {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #EA9623;
  margin-left: auto;
}
@media (min-width: 576px) {
  .main-answers__content .image-block {
    flex: 0 0 110px;
    width: 110px;
    height: 110px;
    margin-right: 30px;
  }
}
@media (min-width: 1200px) {
  .main-answers__content .image-block {
    margin-right: 35px;
  }
}
.main-answers__content .descr-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 2px solid #396EB0;
  border-radius: 25px;
  background-color: #fff;
  margin-top: 28px;
  padding: 18px;
  position: relative;
}
@media (min-width: 576px) {
  .main-answers__content .descr-block {
    flex: 0 0 calc(100% - 140px);
    max-width: calc(100% - 140px);
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .main-answers__content .descr-block {
    margin-top: 30px;
    flex: 0 0 calc(100% - 145px);
    max-width: calc(100% - 145px);
  }
}
.main-answers__content .descr-block:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 24px solid transparent;
  border-right: 24px solid #396EB0;
  border-top: 24px solid transparent;
  right: 67px;
  top: -24px;
}
@media (min-width: 576px) {
  .main-answers__content .descr-block:before {
    left: -24px;
    top: 50px;
    right: auto;
    border-left: 24px solid transparent;
    border-right: 0px solid transparent;
    border-top: 24px solid #396EB0;
  }
}
@media (min-width: 1200px) {
  .main-answers__content .descr-block:before {
    top: 39px;
    left: -35px;
    border-left: 35px solid transparent;
    border-right: 0px solid transparent;
    border-top: 35px solid #396EB0;
  }
}
.main-answers__content .descr-block:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid #fff;
  border-top: 20px solid transparent;
  right: 69px;
  top: -19px;
}
@media (min-width: 576px) {
  .main-answers__content .descr-block:after {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
    right: auto;
    left: -19px;
    top: 52px;
  }
}
@media (min-width: 1200px) {
  .main-answers__content .descr-block:after {
    top: 41px;
    left: -30px;
    border-left: 31px solid transparent;
    border-right: 31px solid transparent;
    border-top: 31px solid #fff;
  }
}
.main-answers__content .descr-block .name, .main-answers__content .descr-block .city {
  width: auto;
  color: #414042;
  font-weight: 700;
}
@media (min-width: 768px) {
  .main-answers__content .descr-block .name span, .main-answers__content .descr-block .city span {
    display: inline-block !important;
  }
}
@media (min-width: 960px) {
  .main-answers__content .descr-block .name, .main-answers__content .descr-block .city {
    width: 100%;
    display: block;
  }
}
@media (min-width: 1200px) {
  .main-answers__content .descr-block .name, .main-answers__content .descr-block .city {
    display: inline-block;
    width: auto;
  }
}
.main-answers__content .descr-block .answer-txt {
  margin-top: 18px;
  line-height: 19px;
  color: #000;
}
@media (min-width: 960px) {
  .main-answers__content .descr-block .answer-txt {
    margin-top: 10px;
  }
}
@media (min-width: 1200px) {
  .main-answers__content .descr-block .answer-txt {
    margin-top: 20px;
  }
}
.main-answers__content__btn-row {
  text-align: center;
}
@media (min-width: 960px) {
  .main-answers__content__btn-row {
    width: 100%;
    flex: 0 0 100%;
    margin-top: 60px;
  }
}
@media (min-width: 1200px) {
  .main-answers__content__btn-row {
    margin-top: 105px;
  }
}
.main-answers__content__btn-row .circles.circles-top:after {
  content: "";
  display: block;
  width: 48vw;
  height: 48vw;
  border-radius: 50%;
  background-color: #C1DDFF;
  position: absolute;
  top: -3.5%;
  right: -19vw;
  z-index: -1;
}
@media (min-width: 576px) {
  .main-answers__content__btn-row .circles.circles-top:after {
    width: 38vw;
    height: 38vw;
    right: -10vw;
  }
}
@media (min-width: 1200px) {
  .main-answers__content__btn-row .circles.circles-top:after {
    width: 302px;
    height: 302px;
    top: -27%;
    right: 7%;
  }
}
.main-answers__content__btn-row .circles.circles-top:before {
  content: "";
  display: block;
  width: 73vw;
  height: 73vw;
  border-radius: 50%;
  position: absolute;
  left: -24.5vw;
  top: 16.5%;
  background-color: #FFD090;
  z-index: -1;
}
@media (min-width: 576px) {
  .main-answers__content__btn-row .circles.circles-top:before {
    width: 60vw;
    height: 60vw;
  }
}
@media (min-width: 1200px) {
  .main-answers__content__btn-row .circles.circles-top:before {
    width: 399px;
    height: 399px;
    left: -25%;
    top: -33%;
  }
}
.main-answers__content__btn-row .circles.circles-center:after {
  content: "";
  display: block;
  width: 63vw;
  height: 63vw;
  border-radius: 50%;
  background-color: #C1DDFF;
  position: absolute;
  top: 56%;
  right: -11vw;
  z-index: -1;
}
@media (min-width: 576px) {
  .main-answers__content__btn-row .circles.circles-center:after {
    right: auto;
    left: -15vw;
    width: 55vw;
    height: 55vw;
    top: 65%;
  }
}
@media (min-width: 1200px) {
  .main-answers__content__btn-row .circles.circles-center:after {
    width: 278px;
    height: 278px;
    left: -22%;
    top: 60%;
  }
}
.main-answers__content__btn-row .circles.circles-center:before {
  content: "";
  display: block;
  width: 39vw;
  height: 39vw;
  border-radius: 50%;
  position: absolute;
  left: 10vw;
  top: 42.5%;
  background-color: #C1DDFF;
  z-index: -1;
}
@media (min-width: 576px) {
  .main-answers__content__btn-row .circles.circles-center:before {
    display: none;
  }
}
@media (min-width: 1200px) {
  .main-answers__content__btn-row .circles.circles-center:before {
    display: block;
    background-color: #FFD090;
    width: 338px;
    height: 338px;
    left: 23.5%;
    top: 16%;
  }
}
.main-answers__content__btn-row .circles.circles-bottom:before {
  content: "";
  display: block;
  width: 57vw;
  height: 57vw;
  border-radius: 50%;
  position: absolute;
  left: -9.5vw;
  top: 76.5%;
  background-color: #FFD090;
  z-index: -1;
}
@media (min-width: 576px) {
  .main-answers__content__btn-row .circles.circles-bottom:before {
    width: 35vw;
    height: 35vw;
    left: auto;
    right: -20vw;
    top: 85%;
  }
}
@media (min-width: 1200px) {
  .main-answers__content__btn-row .circles.circles-bottom:before {
    width: 431px;
    height: 431px;
    right: -25%;
    top: 33.5%;
    background-color: #C1DDFF;
  }
}
.main-answers__content__btn-row .answers-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 25px;
  width: auto;
}
@media (min-width: 1200px) {
  .main-answers__content__btn-row .answers-link {
    padding: 14px 25px;
  }
}

.questions {
  background-color: #396EB0;
  color: #fff;
  padding: 58px 0 60px;
  border-bottom: 2px solid #fff;
}
@media (min-width: 768px) {
  .questions {
    padding: 58px 0 0;
  }
}
@media (min-width: 1200px) {
  .questions {
    padding: 70px 0;
    border-bottom: none;
  }
  .questions .container {
    border: 6px solid #fff;
    border-radius: 25px;
    padding: 42px 60px 32px;
  }
}
.questions__title {
  text-align: center;
  width: calc(100% + 30px);
  margin: 0 -15px;
  padding-bottom: 28px;
  border-bottom: 2px solid #fff;
}
@media (min-width: 768px) {
  .questions__title {
    text-align: left;
    border-bottom: none;
    max-width: 100%;
    margin: 0;
    padding-bottom: 0;
  }
}
.questions__subtitle {
  line-height: 19px;
  text-align: center;
}
@media (min-width: 414px) {
  .questions__subtitle {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .questions__subtitle {
    font-size: 21px;
    line-height: 25px;
  }
}
.questions__content {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  max-width: 345px;
  margin: 0 auto;
}
@media (min-width: 576px) {
  .questions__content {
    max-width: 345px;
  }
}
@media (min-width: 768px) {
  .questions__content {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
}
.questions__content__left-side {
  margin-top: 20px;
}
@media (min-width: 414px) {
  .questions__content__left-side {
    flex: 0 0 65%;
  }
}
@media (min-width: 768px) {
  .questions__content__left-side {
    flex: 0 0 75%;
  }
}
@media (min-width: 1200px) {
  .questions__content__left-side {
    margin-top: 22px;
  }
}
.questions__content__left-side form {
  max-width: 250px;
  margin: 30px auto 0;
}
@media (min-width: 414px) {
  .questions__content__left-side form {
    max-width: 77%;
    margin: 30px 0 58px;
  }
}
@media (min-width: 768px) {
  .questions__content__left-side form {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .questions__content__left-side form {
    margin: 40px auto 0;
  }
}
.questions__content__left-side form .input-group {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .questions__content__left-side form .input-group {
    flex: 0 0 auto;
    width: auto;
    margin: 0 15px 0 0;
  }
}
@media (min-width: 1200px) {
  .questions__content__left-side form .input-group {
    width: 225px;
    margin: 0 20px 0 0;
  }
}
.questions__content__left-side form .input-group.btn-group {
  position: absolute;
  width: 100vw;
  left: 0px;
  border-top: 2px solid #fff;
  text-align: center;
  padding: 10px 0;
}
@media (min-width: 768px) {
  .questions__content__left-side form .input-group.btn-group {
    position: relative;
    left: 0;
    width: auto;
    padding: 0;
    border: none;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .questions__content__left-side form .input-group.btn-group {
    width: 240px;
    margin: 0;
  }
}
.questions__content__left-side form .input-group.btn-group button {
  display: inline-block;
  width: 250px;
  padding: 10px 65px;
}
@media (min-width: 414px) {
  .questions__content__left-side form .input-group.btn-group button {
    width: auto;
  }
}
@media (min-width: 768px) {
  .questions__content__left-side form .input-group.btn-group button {
    width: auto;
    padding: 10px 30px;
  }
}
@media (min-width: 1200px) {
  .questions__content__left-side form .input-group.btn-group button {
    padding: 16px 0;
    display: block;
    width: 100%;
  }
}
.questions__content__left-side form .input-group.check-group label {
  font-size: 12px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .questions__content__left-side form .input-group.check-group label {
    justify-content: flex-start;
    margin-left: 10px;
  }
}
@media (min-width: 768px) {
  .questions__content__left-side form .input-group.check-group {
    order: 5;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin-top: 10px;
  }
}
.questions__content__left-side form input {
  width: 100%;
  border-radius: 25px;
  font-weight: 200;
  font-size: 12px;
  line-height: 14px;
  padding: 12px 20px 14px;
  text-align: center;
  border: 1px solid transparent;
  transition: border-color 0.3s;
}
.questions__content__left-side form input.error-input {
  border-color: red;
}
@media (min-width: 414px) {
  .questions__content__left-side form input {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .questions__content__left-side form input {
    padding: 18px 16px;
  }
}
@media (min-width: 414px) {
  .questions__content__right-side {
    flex: 0 0 35%;
  }
}
@media (min-width: 768px) {
  .questions__content__right-side {
    flex: 0 0 25%;
    position: relative;
  }
}
.questions__content__right-side .questions-image {
  width: 133%;
  max-width: 133%;
  position: relative;
  right: 10px;
}
@media (min-width: 414px) {
  .questions__content__right-side .questions-image {
    display: block !important;
    bottom: 38px;
  }
}
@media (min-width: 768px) {
  .questions__content__right-side .questions-image {
    max-width: 100%;
    width: 100%;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@media (min-width: 1200px) {
  .questions__content__right-side .questions-image {
    bottom: -32px;
    max-width: 240px;
  }
}