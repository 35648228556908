*
  box-sizing: border-box
  outline: none
  &::before, &::after
    box-sizing: border-box
body,
h1, h2, h3, h4, h5, h6, p,
ul[class], ol[class], li,
figure, figcaption, blockquote,
dl, dd
  margin: 0
ul[class], ol[class]
  padding: 0
  list-style-type: none
button, label
  cursor: pointer
a
  &:not([class])
    text-decoration-skip-ink: auto
  &[class]
    text-decoration: none
    color: inherit
input, button, textarea, select
  font: inherit
img
  max-width: 100%
/* main */
body
  display: flex
  flex-direction: column
  min-height: 100vh
  min-width: 320px
  position: relative
  font-family: $default-font
  font-weight: 400
  font-size: $fz-default
  line-height: $lh-default
  color: $txt_color
  background-color: $bg_color
  scroll-behavior: smooth
  text-rendering: optimizeSpeed
  overflow-x: hidden
  @include scrollbars($scrl-bar_width, $scrl-bar_track, $scrl-bar_thumb, $scrl-bar_thumbh, 0)
  &.overflow
    overflow: hidden
.container
  width: 100%
  padding: 0 15px
  margin: 0 auto
  max-width: $container-width + px
  @media (min-width: 1200px)
    padding: 0 5px
a:hover
  text-decoration: none !important